/*
Theme Name:     Huntsbridge
Author:         Granite 5
Version:      	1.0.0
*/

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script:400,700');
@import "core/mixins";

/* variables */
@import "vars/variables";
@import "vars/colours";
@import "vars/breakpoints";
@import "vars/fonts";

/* core */
@import "core/forms";
@import "core/utilities";
@import "core/normalise";

/* Font awesome */
@import "font-awesome/fontawesome";
@import "font-awesome/light";
@import "font-awesome/regular";
@import "font-awesome/solid";
@import "font-awesome/brands";

/* structure */
@import "structure/header";
@import "structure/content";
@import "structure/footer";

/* page templates */
@import "page-templates/block-page";
@import "page-templates/legal";
@import "page-templates/eating";
/* loop templates */
@import "loop-templates/posts";
@import "loop-templates/menus";
@import "loop-templates/rooms";

/* typography */
@import "typography/type";

/* components */
@import "components/breadcrumb";
@import "components/carousel-arrows";
@import "components/search";
@import "components/primary-menu";
@import "components/top-sub-menu";
@import "components/hamburger";
@import "components/share-icons";
@import "components/wp-gallery";
@import "components/carousel-arrows";
@import "components/btn";
@import "components/ajax-loading";
@import "components/swiper";
@import "components/book-cta";
@import "components/gallery-grid";

/* Blocks */
@import "modules/grid";
@import "modules/collapsible";
@import "modules/content-block";
@import "modules/gallery";
@import "modules/form";
@import "modules/content-image";
@import "modules/rooms";
@import "modules/posts_signup";
@import "modules/map-embed";
@import "modules/carousel";

@import "site/site";
@import "site/easings";
@import "site/alignment";
@import "site/single-post";




