.btn {
  @include btn($color-white, $color-black);
}

.colour_bg_black {
  .btn {
    background:$color-off-white;
    border-color: $color-white;
    color:$color-black!important;
    text-decoration: none;

    &:hover {
      background:$color-white;
      color:$color-black!important;
    }
  }
}

.colour_bg_white {
  .btn {
    background:$color-brand;
    text-decoration: none;

    &:hover {
      color:$color-black!important;
    }
  }
}
