.post_and_signup {

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-minwidth-lg() {
      flex-direction: row;
    }

  }

  .post-form-title {
    font-family: $font-fancy;
    font-size:1.7em;
    font-weight:bold;
    margin:0;
  }

  .posts-left {
    margin-bottom:1em;
    position: relative;
    width:100%;

    @include media-minwidth-lg() {
      margin-bottom:0;
      width:60%;
    }

    .post-left--item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-minwidth-md() {
        flex-direction: row;
      }

      &-left {
        padding:2em 2em 0 0;
        width:100%;

        @include media-minwidth-md() {
          width:60%;
        }

        h3 {
          font-family: $font-fancy;
          font-size:2.5em;
          font-weight: 600;
          line-height: 0.9em;

          @include media-minwidth-lg() {
            font-size:3.5em;
          }

        }

        .post-left--item-left-sub {
          color:#979756;
          font-size:1.3em;
          margin:0.5em 0;
        }

      }

      &-right {
        display: none;

        @include media-minwidth-md() {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          width:40%;
        }

        div {
          position: relative;
        }

        .size-post-module {
          padding:0.9em;
        }

        .image-frame-black {
          left:0;
          position: absolute;
          top:0;
        }

      }

      a {
        height:100%;
        left:0;
        position: absolute;
        top:0;
        width:100%;
      }


    }

    &:hover {
      .btn {
        &:after {
          opacity: 1;
        }
      }
    }

  }

  .form-right {
    padding:2em 0 0 0;
    width:100%;

    @include media-minwidth-lg() {
      width:33%;
    }

    h3 {
      font-family: $font-fancy;
      font-size:2em;
      font-weight: 600;
      margin-bottom:0.5em;
    }

    .mc-field-group {
      display: flex;
      margin-top:0.25em;

      label {
        width:7em;
      }

    }

    input[type="email"],
    input[type="text"] {
      background-image:url("assets/images/theme/black-line.svg");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size:100% 6px;
      border:0;
      padding:0;
      width:50%;
    }

    input[type="submit"] {
      cursor: pointer;
      margin-top:0.5em;

      &:hover {
        background-image:url("assets/images/theme/black-line.svg")!important;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: none;
      }


    }



  }

}

