.template-header {
  padding:0;

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media-minwidth-lg() {
      flex-direction: row;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom:2em;
    width:100%;

    @include media-minwidth-lg() {
      margin-bottom:0;
      width:55%;
    }

    h1 {
      margin:0 0 0.25em 0;
    }

  }

  &--links {
    display: flex;
    margin-top:0.5em;

    div {
      margin-right:1em;
    }

  }

  &--image {
    max-width:465px;
    position: relative;
    width: 100%;

    @include media-minwidth-lg() {
      width: 35%;
    }

    .menu-frame {
      left:0;
      position: absolute;
      top:0;
      width:100%;
    }

  }

}

#eating-top {
  padding-bottom:0;

  .container {
    padding-bottom:0;

    @include media-minwidth-lg() {
      padding-bottom:2em;
    }

    .content-image--right {
      margin-bottom:1em;
    }

  }

}

.eating-menu-times.container {
  display: flex;
  flex-direction: column;
  flex-wrap:wrap;
  padding-bottom:4em;
  padding-top:0;

  @include media-minwidth-sm() {
    justify-content: space-between;
    flex-direction: row;
  }
  @include media-minwidth-md() {
    justify-content: flex-start;
    padding-top:1em;
  }

  div {

    width:100%;

    @include media-minwidth-sm {
      width:30%;
    }

    @include media-minwidth-md() {
      font-size:0.9em;
      margin-right:3em;
      width:auto;

      &:last-child {
        padding-right:0;
      }

    }

    @include media-minwidth-lg() {
      font-size:inherit;
      margin:0 2em 1em 0;
      width:auto;
    }

    @include media-minwidth-xl() {
      margin-right:5em;
    }

  }

  p {
    line-height:1.4em;
    margin:1.5em 0 0 0;
    text-align: center;

    @include media-minwidth-md() {
      text-align: left;
    }

    @include media-minwidth-lg() {
      margin:0;
    }

  }

}

.eating-page-menu {
  &--title {
    margin:0 0 1em 0;
  }
}

#menu-tabs {

  .menu-tabs-container {
    max-width:1500px;


    .menu--mob-tab {
      margin:0 0 0.5em 0;
      text-align: center;

      @include media-minwidth-md() {
        display: none;
      }

      button {
        background:#fff;
        color:$color-body;
        font-size:1.1em;
        padding:0.5em 1em;

        &.tab-horizontal-button--active {
          background:$color-brand;
        }

      }


    }

  }

  .menu-tabs--title {
    margin:0.5em 0 1em 0;
    text-align: center;
  }

  .tabs-horizontal {


    @include media-minwidth-md() {
      display: block;
    }

  }

  .tabs-horizontal--buttons {
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: flex-start;

    a {
      background:#fff;
      color:$color-body;
      font-size:1.1em;
      padding:0.5em 1em;
      text-decoration: none;

      &.tab-horizontal-button--active {
        background:$color-brand;
      }
    }


    @media (min-width:1400px) {
      justify-content: center;
    }

    .menu-button {
      margin:5px;
      @include media-minwidth-sm() {
        width:48%;
      }

      @include media-minwidth-md() {
          margin:0 0.25em 0.5em 0.25em;
          width:auto;
        }

      @include media-minwidth-xl() {
        margin:0 0.25em 0.5em 0.25em;

      }

      &:hover {
        background:$color-brand;
      }

    }

  }

  .menu--tabs--content {
    padding:2em 0;
    position: relative;
    text-align: center;

    .menu-download {
     display:none;

      @include media-minwidth-md() {
        align-items: end;
        display:flex;
        justify-content: center;
        margin-bottom:2em;
        text-align:left;
        text-decoration: none;
      }

      @include media-minwidth-xl() {
        left:100px;
        position: absolute;
        top:17px;
      }

      div {
        position: relative;

        i {
          font-size:2em;
          margin-right:0.25em;
          position: absolute;
          transition: $default_transition;

          &.fas {
            opacity: 0;
          }

          &.fal {

          }

        }

      }

      span {
        font-size:0.825em;
        margin-left:2.5em;
      }

      &:hover {

        div {
          i {

            &.fas {
              opacity: 1;
            }

            &.fal {
              opacity: 0;
            }

          }

        }

      }

    }

    .menu-content {

      @include media-minwidth-lg() {
        padding:0 15%;
      }

      @include media-minwidth-xl() {
        padding:0 18%;
      }

      h3 {
        font-family: $font-fancy;
        font-size:2em;
        margin:1em 0;
      }

    }

    &#wine-list-content {

      .menu-download {
        align-items: end;
        display:flex;
        justify-content: center;
        margin-bottom:2em;
        text-align:left;
        text-decoration: none;
      }

    }

  }

}

.menu-gallery {
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  margin:0em auto;
  position: relative;

  img {
    display: block;
    margin: 1em;
  }
}

/*
.menu-post-list.container {
  display: flex;
  flex-direction: column;
  flex-wrap:wrap;
  justify-content: space-between;


  @include media-minwidth-md() {
    flex-direction: row;
    padding:2em;
  }

  @include media-minwidth-xl() {
    padding:5em 2em;
  }

  div {
    margin:2em 0;
    padding:0 2em;
    width:100%;

    @include media-minwidth-md() {
      padding:0;
      width:45%;
    }

    h2 {
      font-size:2em;
      margin:0 0 0.5em 0;

      @include media-minwidth-lg() {
        font-size:2.5em;
      }

    }

    .btn {
      font-size:1.3em;
      padding:0.5em;

      &:hover {
        background: #F0F0EA !important;
        -webkit-transition: 0.3s all ease-in-out;
        transition: 0.3s all ease-in-out;
      }

    }

  }

} */