.content_image {

  .content-image-title {
    margin-bottom:1em;
    text-align: center;
  }

  .content-image-container {
    background:none!important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-minwidth-lg() {
      flex-direction: row;
    }

    &.content-image--image-left {
      flex-direction: column;

      @include media-minwidth-lg() {
        flex-direction: row-reverse;
      }

    }

    .content-image--left {
      width:100%;

      @include media-minwidth-lg() {
        width:45%;
      }

      h1, h2 {
        font-size:2.5em;

        @include media-minwidth-md() {
          font-size:3em;
        }

        @include media-minwidth-xl() {
          font-size:3.5em;
        }

      }

      &-container {
       align-content: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        @include media-minwidth-md() {
          padding-right:3em;
        }

        p {
          line-height: 1.6em;
        }

        .content-image--left--links {

          @include media-minwidth-md() {
            margin-top:0.5em;
          }

          &.content-image-link-vertical {

            @include media-minwidth-sm() {
              width:50%;
            }

            a {
              background:none;
              font-size: 1.2em;
              padding: 0.25em !important;
              text-align: left;
              width:100%;

              &:hover {
                background:$color-off-white;
              }

            }
          }

          a {
            display: block;
            margin:0.5em 0 0 0;
            text-align: center;

            @include media-minwidth-sm() {
              display: inline-block;
              margin:0 0.5em 0 0;
            }

          }

        }

        .content-image--left--contact {

          margin-top:1em;
          text-align: center;

          @include media-minwidth-sm() {
            text-align: left;
          }


          /* @media (min-width:1600px) {
            bottom:0;
            margin-top:0;
            position: absolute;
          } */

          div {
            margin:0.25em 0;
          }

          a {
            color:$color-body;
            text-decoration: none;
          }
        }
      }

    }

    .content-image--right {
      overflow: hidden;
      margin: 2em auto;
      max-width:715px;
      position: relative;
      width:100%;

      @include media-minwidth-lg() {
        margin:0;
        max-width: 100%;
        width:50%;
      }

      &.type-carousel-images {
        @include media-minwidth-lg {
          height:375px;
        }

        @include media-minwidth-xl {
          height:450px;
        }
      }

      .swiper-container {
        height:100%;

        @include media-minwidth-lg() {
          height:auto;
        }

      }

      .swiper-slide {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size:cover!important;
        height:100%;
        padding-bottom:66%;
        width:100%!important;

        @include media-minwidth-lg() {
          padding-bottom:66%;
        }

      }

      .swiper-pagination {
          bottom:120px;

         @media (min-width:1100px) {
         bottom:13%;
        }

      }

      &--frame {
        left: 0;
        position: absolute;
        top:0px;
        width:100%;
        z-index:555;
      }

      .content-right--three-image {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .three-image--image {
          position: relative;
        }

        .three-image--image-1 {
          margin:auto;
          text-align: center;
          div {
            width:100%;
          }

          .content-image--right--frame {
            width:100%;
          }

        }

        .three-image--image-2,
        .three-image--image-3 {
          margin-top:1em;
          width:45%;

          .content-image--right--frame {
            width:auto;
          }

        }

      }

    }

     &.carousel-images {

      .content-image--right {
        max-height:475px;

        @include media-minwidth-lg() {
          height:320px;
          max-height:320px;
        }

        @include media-minwidth-xl() {
          height:100%;
          max-height:475px;
        }


      }
    }

  }

  &.three-images {
    .content-image-container {
      flex-direction: column;

      @include media-minwidth-md() {
        flex-direction: row;
      }

    }
  }

  &.content-images-three {

    .content-image-container {
      flex-direction: column;

      @include media-minwidth-lg() {
        flex-direction: row;
      }

    }

  }

}

.single-events .content-image--left--date {
  display: none;
}

