.carousel-arrow {
  align-items: center;
  justify-content: center;
  background:#fff;
  border:1px solid #666;
  border-radius:50%;
  display: flex;
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;

}

.swiper-prev {
  left:19px;

  @include media-minwidth-xl() {
    left:-45px;
  }

}

.swiper-next {
  right:13px;

  @include media-minwidth-xl() {
    right:-45px;
  }


}