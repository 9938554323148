$theme-folder: "/wp-content/themes/hunts-bridge" !default;

$thin-content: 1000px;
$br-10: 10px;

/* Measures */
$size-auto: auto;
$size-px: 1px;
$size-1: 0.25rem;
$size-2: 0.5rem;
$size-3: 0.75rem;
$size-4: 1rem;
$size-5: 1.25rem;
$size-6: 1.5rem;
$size-7: 1.75rem;
$size-8: 2rem;
$size-10: 2.5rem;
$size-12: 3rem;
$size-16: 4rem;
$size-24: 6rem;
$size-32: 8rem;
$size-48: 12rem;
$size-64: 16rem;
$size-1-2: 50%;
$size-1-3: 33.33333%;
$size-2-3: 66.66667%;
$size-1-4: 25%;
$size-3-4: 75%;
$size-1-5: 20%;
$size-2-5: 40%;
$size-3-5: 60%;
$size-4-5: 80%;
$size-1-6: 16.66667%;
$size-5-6: 83.33333%;
$size-full: 100%;
$size-screen-width: 100vw;
$size-screen-height: 100vh;

/* Transitions */
$default_transition: 0.3s all ease-in-out;