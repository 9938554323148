@mixin list-reset() {
  list-style: none;
  padding: 0;
}

@mixin media-minwidth-sm {
  @media (min-width: $breakp-sm-width) {
    @content;
  }
}

@mixin media-minwidth-md {
  @media (min-width: $breakp-md-width) {
    @content;
  }
}

@mixin media-minwidth-lg {
  @media (min-width: $breakp-lg-width) {
    @content;
  }
}

@mixin media-minwidth-xl {
  @media (min-width: $breakp-xl-width) {
    @content;
  }
}

@mixin target-ieonly {
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin dropdown_menus($top) {

  &.menu-item-has-children {

    > a:before {
      content: "";
    }

    &:hover {
      .sub-menu {
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
      }
    }

    .sub-menu {
      background: none;
      display: block;
      height: auto;
      opacity: 0;
      left:-117px;
      padding: 0.25em 0;
      position: absolute;
      top: $top;
      transform: scaleY(0);
      transform-origin: top center;
      transition: 0.3s all ease-in-out;
      visibility: hidden;
      width: 250px;
      z-index: 100;

      @media (min-width:1600px) {
        left: 0;
      }

      li {
        display: inline-block;
        margin: 0;
        width: 100%;

        a {
          background-color: $color-grey-light;
          border-bottom: 1px solid $color-white;
          color: $color-black;
          display: block;
          font-size: 1rem;
          padding: 0.4em 2em 0.4em 0.75em;
          width:100%;

          &:after {
            display: none;
          }
        }

        &:last-child {
          a { border:0; }
        }

        &:hover,
        &.current-menu-item,
        &.active {

          &:before {
            display: none;
          }

          > a {
            background: $color-brand;
            color: $color-white;
            &:before {
              display: none;
            }
          }

          &:after {
            color:$color-white;
          }

        }

        /* Third level */
        &.menu-item-has-children {

          &:after {
            position: absolute;
            right: 8px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            content: "\f061";
            top: 7px;
            font-size: 0.82em;
          }

          ul.sub-menu {
            height: auto;
            opacity: 0;
            left: auto;
            position: absolute;
            right: -250px;
            top: -5px;
            transform: scaleY(0);
            transform-origin: top center;
            transition: 0.3s all ease-in-out;
            visibility: hidden;

            &.d-none,
            &.menu-back {
              display: none;
            }

          }

          &:hover {
            .sub-menu {
              transform: scaleY(1);
              opacity: 1;
              visibility: visible;
            }
          }

        }

      }

    }

  }

  /* Position third level drop down to the left */
  &:last-child,
  &:nth-last-child(2) {
    ul.sub-menu {
      .menu-item-has-children {
        ul.sub-menu {
          left: -250px;
          right: auto;
        }
      }
    }
  }
}

@mixin btn($background, $color) {
  align-self:center;
  background:$background;
  box-shadow: 20px 38px 34px -29px hsla(0,0%,0%,.2);
  /* border-radius: 255px 15px 225px 15px/15px 225px 15px 255px; */
  color:$color;
  display: inline-block;
  font-family: $font-fancy;
  font-size:1.2em;
  font-weight:$font-weight-bold;
  outline:none;
  padding:0.5em 1em;
  position: relative;
  text-decoration: none;
  transition:all .5s ease;

  @include media-minwidth-xl() {
    font-size:1.5em;
  }

  &:after {
    content:"";
    background-image:url('assets/images/theme/black-line.svg')!important;
    background-repeat:no-repeat!important;
    background-position: bottom!important;
    background-size:cover!important;
    position: absolute;
    bottom: 0;
    height: 5px;
    opacity: 0;
    width: 100%;
    left: 0;
    transition:all .5s ease;
  }

  &:hover {
   box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);

    &:after {
      opacity: 1;
    }
    transition:all .5s ease;

  }

  &.btn-white {
    background:$color-white;
    color: $color-black!important;
  }

  &.btn-brand {
    background:$color-brand;
    color: $color-black!important;
  }

  &.btn-off-white {
    background:$color-off-white;
    color: $color-black!important;
  }

  &.btn-current {
    background:$color-black;
    color:$color-white!important;
  }

  @include target-ieonly() {
    overflow:hidden;
    height:50px;
  }

  &:visited {
    color: $color-black;
  }

  &:focus {
    border: 1px solid #666;
  }


}

@mixin absolute_link() {
  height:100%;
  left:0;
  position: absolute;
  top:0;
  width:100%;
}

@mixin svg_box_image() {
  &:after {
    content:"";
    background-image:url('assets/images/theme/black-line.svg')!important;
    background-repeat:no-repeat!important;
    background-position: 0 bottom!important;
    background-size:cover;
    position: absolute;
    bottom: -3px;
    height: 10px;
    opacity:0;
    width: 100%;
    left: 0;
    transition:all .5s ease;
  }
}