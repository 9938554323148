/* Rooms listing page */
.post-type-archive-menus {

  .block-content-wide {

    @include media-minwidth-md() {
      margin:0 auto;
      padding-top:0;
    }

  }

  h1 {
    text-align: center;
  }

}

.menu-columns {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include media-minwidth-md() {
    height:500px;
    overflow:hidden;
  }

  @include media-minwidth-lg() {
    height:700px;
    overflow:hidden;
  }

  .menu-column {
    position: relative;
    width:100%;

    @include media-minwidth-md() {
      width:50%;
    }

  }

  .menu-container {
    background:$color-off-white;
    padding:5%;

    @include media-minwidth-md() {
      height:700px;
      overflow-y: scroll;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #666;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $color-body;
    }

    .menu-container--block {
      clear:both;
      margin:0 auto 2em 0;
      max-width:900px;

      .menu-block {
        margin:auto;
        text-align: center;

        h2 {
          font-size:2em;
        }

        &--dish {
          margin:2em 0;

          h4 {
            font-family: $font-family;
            font-size:1em;
            font-weight:100;
            line-height:1.3em;
            margin:0;
          }

          p {
            font-family: $font-fancy;
            font-size:1.05em;
          }

        }

        img {
          margin:1em 0;
          width:30%;
        }

      }


    }

  }

}

.menu-images {

  .swiper-container {
    height:100%;

    .swiper-slide {
      background-position: 50% 50%;
      background-repeat:no-repeat;
      background-size:cover;
      height:300px;

      @include media-minwidth-md() {
        height:100%;
        padding-bottom:0;
      }

    }

  }

  .swiper-pagination {
    left:0;
    margin:auto;
    right:0;

    span {
      margin:0 4px;
    }

  }

}

.single-menus {

  .entry-content {
    margin:3em auto;
    max-width:1000px;

    @include media-minwidth-md() {
      padding:0 6em;
    }

    @include media-minwidth-xl() {
      padding:0;
    }

  }


  .menu-container {



  }

}

.menu-list {
  display:flex;
  flex-wrap:wrap;
  flex-direction: column;
  justify-content: center;

  @include media-minwidth-sm() {
    flex-direction: row;
  }

  @include media-minwidth-lg() {
    justify-content: start;
  }

  div {
    max-width:240px;
    width:100%;

    @include media-minwidth-sm() {
      margin-right:1em;
      width:45%;
    }

    @include media-minwidth-sm() {
      max-width:100%;
    }

    a {
      font-size:1.2em;
    }

  }

  .menu-button {
    background: $color-white !important;
    display:block;
    padding: 0.25em!important;
    transition: 0.3s all ease-in-out;

    @include media-minwidth-sm() {
      display: block;
    }

    &:hover {
      background:$color-off-white!important;
      transition: 0.3s all ease-in-out;
    }

  }

}

.post-type-archive-menus {
  article.room-listing {

    @include media-minwidth-xl() {
      height: 510px;
    }

    h2 {
      margin-bottom:0.5em!important;
      width:100%;
    }

    p {

    }


  }
}