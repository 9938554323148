.share-icons {
  align-items: center;
  display:flex;
  justify-content: center;
  padding:0;
  position: relative;
  top:0;
  left:0;
  z-index:8888;

  @include media-minwidth-md() {
    position: absolute;
  }

  &:hover {

    .share-icon-wrap {

      div {
        @include media-minwidth-md() {
          width:100%;
          transition: 0.3s all ease-in-out;
        }
      }

    }

  }

  @include media-minwidth-md() {
    margin:0 auto;
  }

  .share-icon-wrap {
    display: flex;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    div {
      @include media-minwidth-md() {
        width:0;
        transition: 0.3s all ease-in-out;
      }
    }



  }

  div {

    border-right:1px solid #e0e0e0;

    &.share-icons--share {
      align-items: center;
      background:$color-body;
      display: flex;
      i {
        background:$color-body;
        color:$color-white;
        margin:0;
      }

      &:hover {
        i {
          margin-top:0!important;
        }
      }

    }

    &:last-child {
      border:0;
    }

    i {
      background:$color-off-white;
      color:$color-black;
      font-size: 1.2em;
      padding: 15px;
      transition: 0.3s all ease-in-out;

      &:hover {
          background:$color-black;
          color:$color-white;
          transition: 0.3s all ease-in-out;
      }

    }

  }

}

.social-icons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin:0 0 1em 0;

  div {
    margin:0 0.5em;

    img {
      width:25px;
    }

  }

}