.sf {
  background: $color-footer-bg;
  color: $color-footer-font;
  padding: 1em 0 0 0;
  position: relative;

  @include media-minwidth-md() {
    padding: 3em 0 0 0;
  }

  a {
    color: $color-footer-font;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }

  h3 {
    font-family: $font-fancy;
    font-size:1.75em;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  #back-to-top {
    background:$color-body;
    bottom:0;
    margin:0.75em;
    padding:0.25em;
    position: absolute;
    right:0;
    text-align: center;
    width:3em;
    z-index:9999;

    @include media-minwidth-md() {
      margin:0;
      transition:0.3s all ease-in-out;
    }

    i {
      color:#fff;
      font-size:2.5em;
    }


  }

  .footer-ivy {

    display: none;

    @include media-minwidth-md() {
      display:block;
      position: absolute;
      right:0;
      top: -83px;
      width: 380px;
    }

    @include media-minwidth-lg() {
      right: -25px;
      width: 446px;
    }


  }

  .sf__footer--three{
    p {
      margin:0;
    }

    li {
      margin:0.25em 0;
    }

  }

  form {

    p {
      margin:0 0 0.5em 0;
    }

    input {
      background:none;
      border:1px solid #666;
      font-size:0.825em;
    }

    input[type="submit"] {
      background:$color-brand;
      border:0;
      cursor: pointer;

    }

  }

}

@include target-ieonly {
  .sf {
    float: left;
    width: 100%;
  }
}

.sf__container {
  padding:2em 1em;
  position: relative;
  z-index:8888;


  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 0.75em;
    }
  }
}

.sf__container__top {

  @include media-minwidth-sm() {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-minwidth-lg() {
    padding:0;
    justify-content: space-between;
  }

}

.sf__footer__column {
  margin-top:2em;
  position: relative;
  text-align: center;
  width:100%;

  @include media-minwidth-md() {
    width:50%;
  }

  @include media-minwidth-lg() {
    padding-right:1em;
    margin-bottom:0;
    width:25%;
  }

  &:last-child {
    padding-right:0;
  }

  .sub-menu {
    display: none;
  }

  &:after {
    content:"";
    height:130px;
    margin:auto;
    left:0;
    position: absolute;
    right:0;
    width:200px;

    @include media-minwidth-md() {
      height:100%;
      left:initial;
      right:0;
      top:30px;
      transform: rotate(0);
      width:10px;

    }

  }


}

.sf__footer--image {

  @include media-minwidth-lg() {
    width:25%;
  }

  &:after {

    @include media-minwidth-md {
        background:url('assets/images/theme/divider-1.png') 0 0 no-repeat;
    }

  }

  @include target-ieonly() {
    background:none;
  }

}

.sf__footer--one {

  &:after {

    @include media-minwidth-md() {
      background:url('assets/images/theme/divider-2.png') 0 0 no-repeat;
    }

    @include target-ieonly() {
      background:none;
    }

  }


}

.sf__footer--two {

  &:after {

    @include media-minwidth-lg() {
      background:url('assets/images/theme/divider-3.png') 0 0 no-repeat;
    }

    @include target-ieonly() {
      background:none;
    }

  }


}

.sf__bottom {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding:1em 0;
  width: $size-full;

  @include media-minwidth-md() {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .container {
    padding:0 2em;
  }

  p {
    font-size:0.9em;
    margin:0;
  }

  a {
    color:$color-body;
  }

}

.ivy-side-image {
  display: none;

  @include media-minwidth-xl() {
    display: block;
  }

  @media (min-width:1200px) and (max-width:1500px) {
    width:11%;
  }

}

.ivy-left {
  top: 62%;
  position: absolute;
  left: -7%;


  transform: rotate(165deg);
}

.ivy-background {
    display: none;

  @include media-minwidth-lg() {
    display: block;
    position: absolute;
    right: -21%;
    top: 4%;
    z-index:111;
  }

  @media (min-width:1200px) and (max-width:1600px) {
    width:78%;
  }

  }

.ivy-right {
    top: 185%;
    position: absolute;
    right: -5%;
  }

.ivy-left-2 {
    left: -5%;
    top: 280%;
    position: absolute;
  }