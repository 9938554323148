/* Fonts */
$font-family: 'Source Sans Pro', sans-serif;
$font-fancy: 'Dancing Script', cursive;
$font-weight-normal: 100;
$font-weight-bold: 700;

$line-height-none: 1;
$line-height-tight: 1.25;
$line-height-normal: 1.5;
$line-height-loose: 2;

$letterspacing-tight: -0.05em;
$letterspacing-normal: 0;
$letterspacing-wide: 0.05em;

$text-xs: .75rem; // 12px
$text-sm: .875rem; // 14px
$text-base: 1rem; // 16px
$text-lg: 1.125rem; // 18px
$text-xl: 1.25rem; // 20px
$text-2xl: 1.5rem; // 24px
$text-3xl: 1.875rem; // 30px
$text-4xl: 2.25rem; // 36px
$text-5xl: 3rem; // 48px