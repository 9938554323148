.alignleft {
  display: inline;
  float: left;
  margin:0 1.5em 1.5em 0;
}

.alignright {
  display: inline;
  float: right;
  margin:0 0 1.5em 1.5em;
}

.aligncenter {
  clear: both;
  margin:1em auto;
  text-align: center;
}

/* Positional alignment */
.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-self-start {
  align-items: self-start;
}

.align-items-self-end {
  align-items: self-end;
}

.align-items-f-start {
  align-items: flex-start;
}

.align-items-f-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}