.ajax-loading {
  align-items: center;
  background:rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  height:100%;
  left:0;
  margin:auto;
  position: fixed;
  right:0;
  top:0;
  width:100%;
  z-index:9999;
  transition: 0.3s all ease-in-out;

  .loading-spinner {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 3px solid #fff;
    border-radius: 50%;
    border-top-color: $color-brand;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

}