.swiper-pagination {
  bottom:48px;
  z-index:6666!important;

  .swiper-pagination-bullet {
    background:#e0e0e0;
    cursor: pointer;
    height:18px;
    opacity: 1;
    width:18px;
    z-index:8888;

    &-active {
      background:$color-brand;
    }

  }

}

.swiper-arrow {
  i {
    color: #333;
    font-size: 4em;
  }
}

.swiper-carousel-arrows {
  bottom:0;
  height:2em;
  position: absolute;
  left:0;
  margin:auto;
  right:0;
  width: 10em;
  z-index:-1!important;

  @include media-minwidth-lg() {
    height:auto;
    width: 100%;
    top: 52%;
  }

  &.carousel-module-arrows {
    @include media-minwidth-lg() {
      top: 35%;
    }
  }

  .carousel-nav-arrow {
    bottom:-20px;
    position: absolute;
    z-index:8888;

    @include media-minwidth-lg() {
      bottom:initial;
    }


    i {
      color:#333;
      font-size:4em;
    }

  }

  .carousel-prev {
    left:41px;

    @include media-minwidth-xl() {
      left:-8%;
    }

  }

  .carousel-next {
    right:41px;

    @include media-minwidth-xl() {
      right:-8%;
    }

  }


}

.colour_bg_black {
  .swiper-arrow {
    i {
      color:$color-white;
    }
  }
}