/* Room filter */
.filter-navigation.room-navigation {
    display: flex;
    justify-content: center;
    margin-bottom:1em;

  @include media-minwidth-md {
    margin:2em 0;
  }

  .room-navigation--buttons {
    display: none;

    @include media-minwidth-lg() {
      display: flex;
      .btn {
        font-family: $font-family;
        font-size:0.9em;
        font-weight:100;
      }
    }

    @include media-minwidth-xl() {
      .btn {
        font-size:1.2rem;

      }
    }

    @include target-ieonly() {
      .room-filter-button {
        height:35px;
      }
    }

  }

  .btn {
    margin-right:1em;

    &:focus {
      outline: 0;
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width:100%;

    @include media-minwidth-sm() {
      flex-direction: row;
      justify-content: center;
      margin-top:1em;
    }

    @include media-minwidth-lg() {
      display: none;
    }

    div {
      align-self: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      width:70%;

      @include media-minwidth-sm() {
        justify-content: flex-start;
        flex-direction: row;
      }

      label {
        align-items: center;
        display: flex;
        margin:0 0 0.5em 0;
        width:10em;

        @include media-minwidth-sm() {
          margin:0 0.5em 0 0;
        }

      }

      select {
        width:100%;
        @include media-minwidth-sm() {
          margin-bottom:0;
        }
      }

      input {
        width:100%;
      }

    }

    #room-filter-submit {
      background:$color-brand;
      color:$color-black;
      cursor: pointer;
      height:41px;
      margin-top:0.5em;
      width:100%;

      @include media-minwidth-sm() {
        margin-top:0;
      }

    }

  }

}

.mobile-room-filter {
  background:$color-grey-light;
  margin-top:0!important;
  padding:1em 0.5em;
  width:auto;

  select {
    text-align: center;
  }

}

/* Rooms listing page */
.post-type-archive-rooms {

  .block-content-wide {

    @include media-minwidth-md() {
      margin:0 auto;
      padding-top:0;
    }

  }

  h1 {
    text-align: center;
  }

  article.room-listing {
    opacity: 0.4;
    transform: scale(0.9);

    &.article--show {
      opacity: 1;
      transition: 0.3s all ease-in-out;
      transform: scale(1);
    }

  }

}

.room-content-container {
  margin:2em 0;

  @include media-minwidth-lg() {
    margin:0 0 3em 0;
    width:70%;
  }

}

.room-listing-container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap:50px;

  @include media-minwidth-md() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-minwidth-lg() {
    grid-template-columns: repeat(3, 30%);
  }


  @include target-ieonly() {
    display: flex;
    flex-wrap:wrap;
  }

  &--desc {
    margin:0 auto 2em auto;
    max-width: $breakp-lg-width;
    text-align: center;
  }

}

article.room-listing {
  display: flex;
  flex-direction: column;
  margin-bottom:2em;
  overflow: hidden;
  padding-bottom:1em;
  position: relative;
  max-width:350px;
  width:100%;

  @include media-minwidth-lg() {
    margin-bottom:1em;
    padding-bottom:0;
  }

  @include target-ieonly() {
    margin:1em 0;
    width:100%;

    @include media-minwidth-sm() {
      margin-right:2em;
      width:45%;

      &:nth-child(2n) {
        margin-right:0;
      }

    }

    @include media-minwidth-md() {
      margin-right:2em;
      width:30%;

      &:nth-child(2n) {
        margin-right:2em;
      }

      &:nth-child(3n) {
        margin-right:0;
      }

    }

  }

  .room-listing--image {
    overflow: hidden;

    img {
      display: block;
      width:100%;
    }
  }

  .room-listing--content {
    padding:1.5em 0 0 0;
    width:100%;

    @include media-minwidth-md() {
      padding:1.5em 0;
    }

    header {
      margin-bottom:1em;
      text-align: center;

      @include media-minwidth-xl() {
        height:3em;
        text-align: left;
      }

      .room-listing--header {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-minwidth-xl() {
          flex-direction: row;
        }

        h2 {
          font-size:2.5em;
          margin:0;
        }

        .post-content--meta {
          margin-top:1.5em;
          justify-content: center;

          @include media-minwidth-xl() {
            justify-content: flex-end;
            margin-top:0;
            width:100%;
          }

          .post-content--categories {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            span {
              align-items: center;
              justify-content: center;
              display: flex;
              font-size:1em;
              margin-left:0.5em;
              padding:0.25em 0.5em;
              text-align: center;
            }
          }

        }

        .bed-size {
          font-size:0.8em;
          margin:0;
        }

      }

    }

    p {
      height:6em;
      margin:0 0 1em 0;
      /* text-align: center; */
    }

    p.room-items--content-link-text {
      font-family: $font-fancy;
      font-size:1.8em;
      font-weight: bold;
      margin:0!important;


      @include media-minwidth-lg() {
        bottom: 23px;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
      }

    }

  }

  .room-listing--content--meta {
    display: flex;

  }

  @include svg_box_image();

  &:hover {
    transition: 0.3s all ease-in-out;

    &:after {
      opacity: 1;
    }

    .room-carousel-arrows {
      opacity: 1;
    }

  }

}

/* Room taxonomy page */
.tax-rooms_cats {

  .block-content-wide {

    @include media-minwidth-md() {
      margin:0 auto;
      padding-top:0;
    }

  }

  article {
    margin:auto;
    opacity: 1;
    transform: scale(1);
  }


}

.mobile-book-room {
  margin:2em auto;
  text-align: center;

  @include media-minwidth-lg() {
    display: none;
  }

  a {
    font-size:1.5em;
  }

}

/* single room post
.single-rooms {

  h1 {
    margin:0;
  }

  .room-intro {
    margin:2em 0;

    p {
      margin:0;
    }

  }

  .post-content--meta {

    .post-content--categories {
      margin:0;
    }
  }

  .room-carousel {
    margin:0 auto 2em auto;

    .swiper-pagination {
      margin-top:2em;
      position: relative;
    }


  }


}

*/