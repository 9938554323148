html {
  height:100%;
}

.screen-reader-text {
  background:$color-body;
  color:#fff;
  left:0;
  padding:10px;
  position: absolute;
  text-decoration: none;
  top:0;
  z-index:8888;

  &:focus {
    z-index:9999;
  }


}

body {
 /* background-image:url('assets/images/theme/ivy-bg.png');
  background-position: -150% 0; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;

  @include target-ieonly() {
    height:auto;
  }


}

.fixed-header {
  display: none;
  @include media-minwidth-lg() {
    background:$color-off-white;
    display: block;
    box-shadow: 0 0 9px 0 $color-black;
    position: fixed;
    top:-100px;
    width:100%;
    transition: 0.3s all ease-in-out;
    z-index:9999;
  }

  &.fixed-header-show {
    top:0;
    transition: 0.3s all ease-in-out;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom:0.5em;
    padding-top:0.5em;

    .fixed-header--right {
      align-items: center;
      display: flex;

      .header-main-nav {
        background:none;
        margin:0 2em 0 0;

        ul {
          li {
            margin:0 0 0 1.75em;
            a {
              color:$color-top-nav-font;
              font-size:1.2em;
              text-decoration: none;

              @include media-minwidth-xl() {
                font-size:1.5em;
              }

            }
          }
        }

      }

      a {
        color:$color-top-nav-font;
      }

      .btn {
        font-size:1.2em;
        margin-right:1em;
        padding:0.5em 0.5em;
      }

    }

    .fixed-header--left {
      padding:0.5em 0;
    }

  }

}

.sh {
  position: fixed;
  width:100%;
  z-index:9999;

  @include media-minwidth-lg() {
    position: relative;
  }

  a {
    color:$color-top-nav-font;
    text-decoration: none;
  }

  &__content {
    background:$color-primary-nav-bg;
    position: relative;
    width:100%;
    transition: $default_transition;
    z-index:999;

    .sh__content__container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;

      &.container {
        padding:0 2em;
      }

      .mobile-buttons {
        align-items: center;
        display: flex;
        position: relative;
        z-index:8888;

        a {

          i {
            color:$color-hamburger;
            font-size:1.2em;
            margin:0 0.5em;
          }

          @include media-minwidth-lg() {
            display:none;
          }

        }

        @include media-minwidth-lg() {
          display: none;
        }
      }

    }

    &.header-fixed {
      box-shadow: 0 0 10px -4px #000;
      padding:0.5em 0;
      transition: $default_transition;
      z-index:9999;

      .sh__content__sub_menu {
        padding:0;
      }


    }

  }

  &__content__logo {
    margin:1.5em 0;
    width:8em;
    transition: $default_transition;

    @include media-minwidth-lg() {
      margin:0;
      width:auto;
    }

    .logo-hotel {
      display: none;

      @include media-minwidth-sm() {
        display: block;
      }

      @include target-ieonly() {
        height:76px;
      }

    }

    .logo {
      @include target-ieonly() {
        height:45px;
        margin-top:1em;
      }
    }

    a {
      display: flex;
    }

    img {
      display: block;

      @include media-minwidth-lg() {
        width:100%;
      }


    }
  }

  &__content__sub_menu {
    display: flex;
    justify-content: flex-end;
    padding:2em 0 0.5em 0;
  }

}

@media(max-width:$breakp-lg-width) {

  .nav-open .header-main-nav {
    box-shadow: 0.625rem 0 1.375rem -0.5rem rgba(0, 0, 0, .35);
    left: 0;
    transition: all .2s ease-in-out;
  }

}