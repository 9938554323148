@media(max-width: $breakp-lg-width) {
  body.nav-open #content:after {
    background-color: rgba(0, 0, 0, .8);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 888;
  }

  .nav-open {
    .sh__masthead {
      z-index:555;
    }

    .sh__content {
      background: transparent;
      border-color: transparent;
    }
  }

}

.header-main-nav {
  background: $color-slide-nav-bg;
  height: 100vh;
  left: 0;
  position: fixed;
  left: -240px;
  top: 0;
  width: 240px;
  z-index: 9999;
  transition: all .2s ease-in-out;

  ul {
    margin: 0;
    padding: 0;

    li {
      background: $color-slide-nav-bg;
      border-bottom: 1px solid $color-grey-light;
      list-style: none;

      &.active,
      &:hover {
        > a {
          background:$color-slide-nav-bg-active;
          color: $color-slide-nav-font-active;
        }
      }

      &.menu-item-has-children {

        span {
          content: "\f105";
          color:$color-slide-nav-font;
          font-family: "Font Awesome 5 Pro";
          font-size: 22px;
          padding:12px 15px;
          position: absolute;
          right: 0;
          vertical-align: middle;
        }

        ul.sub-menu {
          background-color: $color-slide-nav-bg;
          height: 100%;
          left: 0;
          list-style: none;
          display: block;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          transition: all .2s ease-in-out;
          width: 100%;
          z-index: 7;
          &.in {
            transform: translateX(0);
          }
        }
        > a {
          position: relative;
          width:85%;
          /* &:before {
             content: "\f105";
             color:$color-slide-nav-font;
             font-family: "Font Awesome 5 Pro";
             font-size: 22px;
             padding-right: 6px;
             position: absolute;
             right: 15px;
             top: 11px;
             vertical-align: middle;
           } */
        }

        &:hover,
        &.active {
          > a {
            &:before {
              color: $color-slide-nav-font-active;
            }
          }
        }

      }
    }

    a {
      color: $color-slide-nav-font;
      display: block;
      font-size:0.9em;
      padding: 0.75em 1em;
    }
  }
}

@include media-minwidth-lg {

  .header-main-nav {
    align-items: center;
    background: $color-primary-nav-bg;
    border-bottom: 0;
    display: flex;
    height: auto;
    flex-flow: row nowrap;
    font-size:0.9em;
    justify-content: center;
    left: 0;
    margin-bottom:1em;
    position: relative;
    top: 0;
    width: auto;
    transition: none;
    z-index:999;

    > ul {
      display: flex;

      .menu-item {
        background: none;
        border: 0;
        @include list-reset;
        margin: 0 0 0 1em;
        position: relative;

        &:after {
          content:"";
          background-image:url('assets/images/theme/black-line.svg')!important;
          background-repeat:no-repeat!important;
          background-position: 0 bottom!important;
          background-size:100%!important;
          position: absolute;
          bottom: -3px;
          height: 10px;
          opacity: 0;
          width: 100%;
          left: 0;
          transition:all .5s ease;
        }

        &.active,
        &:hover {
          &:after {
            opacity: 1;
          }

          a {
            background: none;
            color: inherit;
          }

        }

        &.mobile-parent {
          display: none!important;
        }

        > a {
          color: $color-primary-nav-font;
          font-family: $font-fancy;
          font-size: 1.4em;
          font-weight:bold;
          padding: $size-3 0;

          @include media-minwidth-xl() {
            font-size: 1.75em;
          }

          &:before {
            display: none;
          }
        }

        /* Second level */
        &.menu-item-has-children {

          &:hover {
            .sub-menu {
              transform: scaleY(1);
              opacity: 1;
              visibility: visible;
            }
          }

          a {
            width:100%;
          }

          .sub-menu {
            background: none;
            height: auto;
            opacity: 0;
            left:0;
            padding: 0.25em 0;
            position: absolute;
            top: 48px;
            transform: scaleY(0);
            transform-origin: top center;
            transition: 0.3s all ease-in-out;
            visibility: hidden;
            width: 250px;
            z-index: 100;

            @media (min-width:1600px) {
              left: 0;
            }

            li {
              display: inline-block;
              margin: 0;
              width: 100%;

              a {
                background-color: $color-grey-light;
                border-bottom: 1px solid $color-white;
                color: $color-black;
                font-family: $font-family;
                font-size: 1rem;
                font-weight:300;
                padding: 0.4em 2em 0.4em 0.75em;
              }

              &:last-child {
                a {
                  border:0;
                }
              }

              &:hover,
              &.current-menu-item,
              &.active {
                &:after {
                  display: none;
                }

                > a {
                  background: #666;
                  color: $color-white;
                  &:before {
                    display: none;
                  }
                }

                &:after {
                  color:$color-white;
                }

              }

              /* Third level */
              &.menu-item-has-children {

                &:after {
                  position: absolute;
                  right: 8px;
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 300;
                  content: "\f061";
                  top: 7px;
                  font-size: 0.82em;
                }

                ul.sub-menu {
                  height: auto;
                  opacity: 0;
                  left: auto;
                  position: absolute;
                  right: -250px;
                  top: -5px;
                  transform: scaleY(0);
                  transform-origin: top center;
                  transition: 0.3s all ease-in-out;
                  visibility: hidden;
                }

                &:hover {
                  .sub-menu {
                    transform: scaleY(1);
                    opacity: 1;
                    visibility: visible;
                  }
                }

              }

            }

          }

        }

        /* Position third level drop down to the left */
        &:last-child,
        &:nth-last-child(2) {
          ul.sub-menu {
            .menu-item-has-children {
              ul.sub-menu {
                left: -250px;
                right: auto;
              }
            }
          }
        }

      }
    }
  }
}

@include media-minwidth-xl {
  .header-main-nav {
    > ul {
      .menu-item {
        margin: 0 0 0 3em;

      }
    }
  }
}

.home {
  .menu-wine-shop.active {
    &:after {
      display: none;
    }
  }
}
