.container {
  padding:2em;
  position: relative;
  z-index:888;
  width: 100%;

  @include media-minwidth-md {
    padding:2em 3em;
  }

  @include media-minwidth-lg {
    margin:auto;
    max-width: $breakp-xl-width;
    padding:2em;
  }

  @media(min-width:1600px) {
    max-width: 1400px;
    padding:2em;
  }
}

.content-container {
  margin:auto;
  max-width: $breakp-xl-width;
  padding:2em;
  position: relative;

  @media(min-width:1600px) {
    max-width: 1500px;
    padding:2em 5em;
  }

  @include target-ieonly() {
    width:$breakp-xl-width;
  }

}

.container-thin {
  max-width:$breakp-lg-width;
}

.thin-container {
  margin:auto;
  padding:2em;
  position: relative;

  @include media-minwidth-md() {
    padding:0 20em 0 2em;
  }


  @include media-minwidth-xl() {
    padding:0 30% 0 2em;
  }

}

#content {
  padding-top:5em;

  @include media-minwidth-sm() {
    padding-top:7em;
  }

  @include media-minwidth-lg() {
    padding-top:0;
  }

}

.sc--full-width {
  #primary {
    padding-right: 0;
  }
  @include target-ieonly {
    width: 100%;
  }
}