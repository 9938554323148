.legal-container {
  display: flex;
  flex-wrap: wrap;

  .sidebar-wrap {
    display: none;
    margin-bottom:2em;

    @include media-minwidth-lg() {
     display: block;
      width:20%;
    }

    ul {
      display:flex;
      flex-direction: row;
      margin:0;
      padding:0;

      @include media-minwidth-md() {
        flex-direction: column;
      }


      li {
        margin:0 0.5em 0 0;

        @include media-minwidth-md() {
          margin:0.5em 0 0 0;
        }


        a {
          color:$color-body;
          display: block;
          text-decoration: none;
        }
      }
    }

  }

  .legal-container--content {

    @include media-minwidth-md() {
      width:100%;
    }

    @include media-minwidth-lg() {
      padding-left:3em;
      width:75%;
    }

    @include media-minwidth-xl() {
      width:80%;
    }

    .legal-block {
      margin-top:2em;
    }

  }

}