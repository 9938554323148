.booking-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @include media-minwidth-sm() {
    flex-direction: row;
    justify-content: flex-start;
  }

  @include media-minwidth-lg() {
    margin:2em 0 0 0;
  }


  a {
    color:$color-body;
    text-decoration: none;
  }
  
  .booking-buttons--cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-minwidth-md() {
      flex-direction: row
    }

    div {

      margin:1em 1em 0 0;


      @include media-minwidth-md() {
        margin-right:1em;
      }

      @include media-minwidth-lg() {
        margin:0 1em 1em 0;
      }

      @include media-minwidth-xl() {
        margin:0 1em 0 0;
      }


    }

  }

  .booking-buttons--contact {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1em 0;
      width:100%;

    @include media-minwidth-sm() {
      justify-content: flex-start;
    }

    @include media-minwidth-lg() {
      margin: 3em 0;
    }

    div {
      margin-right:1em;
    }

  }

  .booking-buttons--contact {
    display: flex;

    p {
      margin:0 1em 0 0;
    }

  }



}

.cta-bar {
  background:$color-brand;
  padding:1em 0;
  text-align: center;

  @include media-minwidth-md() {
    padding:0;
  }

  .booking-buttons,
  .booking-buttons--contact {
    margin:0 auto;
    justify-content: center;
  }

  .booking-buttons--contact {
    margin:1em 0 0 0;
  }

}