input[type=text], input[type=password], input[type=email], input[type=number], input[type=tel], select, textarea {
  background: #fff;
  color: $color-black;
  border: 1px solid #e0e0e0;
  padding:0.5em;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
  background: $color-grey-light;
  color: $color-black;
  border: $color-grey;
  padding: $size-3 $size-4;
  min-height: 2.6rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
  color: $color-black;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 7px;
}

.gform_wrapper {
  background:none;
  margin: 2em 0!important;
  padding:0;


  li.gfield {
    margin-bottom:0.5em;

    input,
    select,
    textarea {
      border:1px solid $color-grey-dark;
      padding:0.5em!important;
    }

    #input_5_24_3_container,
    #input_5_27_6_container {
      label {
        display: none;
      }
    }

  }

  input[type="submit"] {
    background:$color-brand;
    color:$color-body;
    cursor: pointer;
    padding:0.75em;
    transition: $default_transition;

    &:hover {
      background:$color-body;
      color:$color-white;
      transition: $default_transition;
    }


  }



}

.form-block {

  .gform_wrapper {
    .validation_error {
      border:0;
      color:red;
      padding:0;
      text-align:left;
    }

    .gfield_error {
      border:0!important;
      background:none!important;

      .gfield_label,
      .validation_message {
        color:red!important;
      }

    }

  }
}

