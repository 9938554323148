$theme-folder: "/wp-content/themes/fpm" !default;

/* Colours */
$color-transparent: 'transparent';
$color-black: #191919;
$color-grey-dark: #646464;
$color-grey: #B2B2B1;
$color-grey-light: #EBE9E9;
$color-grey-lightest: #F9F9F9;
$color-white: #ffffff;

/* Branding */
$color-brand: #E0E476;
$color-off-white: #F0F0EA;

/* structure */
$color-top-nav-bg: $color-black;
$color-top-nav-font: $color-black;
$color-primary-nav-bg: $color-white;
$color-primary-nav-font: $color-black;

$color-slide-nav-bg: $color-black;
$color-slide-nav-bg-active: $color-brand;
$color-slide-nav-font: $color-white;
$color-slide-nav-font-active: $color-black;
$color-hamburger: $color-black;
$color-hamburger-active: $color-white;

$color-footer-bg: $color-off-white;
$color-footer-font: $color-black;


/* Fonts */
$color-body: $color-black;
$color-link: $color-grey-dark;
$color-link-visited: $color-grey-dark;
$color-h1: $color-body;
$color-h2: $color-body;

/* notices */
$color-danger: #de751f;
$color-warning: #f2d024;
$color-success: #1f9d55;
$color-info: #2779bd;