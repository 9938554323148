.sh__content__sub {

  .sh__masthead__menu {
    display: none;
    margin-right:1em;

    @include media-minwidth-lg() {
      display: block;
    }

    ul {
      display:flex;
      justify-content: flex-end;
      margin:0;
      width:100%;

      li {
        margin-left:1.5em;
        list-style: none;
        position: relative;

        &.active {
          > a {
            font-weight: bold;
          }
        }

        &.menu-item-has-children {

          > a:before {
            content: "";
          }

          .sub-menu {
            display: block;
            justify-content: flex-start;
            left: 0;
            height: auto;
            padding: 15px 0 0 0;
            position: absolute;
            top: inherit;
            transform: scaleY(0);
            transform-origin: top left;
            transition: transform 0.2s ease-in-out, visibility 0.1s ease-in-out;
            visibility: hidden;
            width: 200px;
            z-index:9999;

            li {
              display: inline-block;
              margin: 0;
              width: 100%;

              &.active,
              &:hover {
                font-weight:bold;
              }

              a {
                background-color: $color-grey-light;
                border-bottom: 1px solid $color-white;
                color: $color-black;
                display: block;
                font-size: 1em;
                padding: 0.4em 2em 0.4em 0.75em;
              }
            }
          }

          &:hover {
            .sub-menu {
              transform: scaleY(1);
              transition: transform 0.2s ease-in-out, visibility 0.4s ease-in-out;
              visibility: visible;
            }
          }
        }

        a {
          color:$color-top-nav-font;
        }

      }
    }

  }

  .sh__masthead__container--search {
    display: none;

    @include media-minwidth-lg() {
      display: block;
    }

  }

}

