.post-listing-container {
  margin:0 auto;
  position: relative;

  .post-columns {
    display: flex;
    justify-content: flex-start;
  }

  .post-listing-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-minwidth-md() {
      padding:0 ;
    }

    article {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin:2em auto 0.5em auto;
      opacity: 0.25;
      padding:1em;
      position: relative;
      width:100%;
      transition: 0.3s all ease-in-out;

      &.article--show {
        opacity: 1;
        transition: 0.3s all ease-in-out;
      }

      @include media-minwidth-sm() {
        margin:0 0 0.5em 0;
        width:49%;
      }

      @include media-minwidth-md() {
        margin-bottom:1em;
        max-width:100%;
        padding:0;
        width:30%;
      }

      @include media-minwidth-lg() {
        flex-direction: row;
        margin-bottom:3em;
        padding: 0;
        width:49%;
      }


      &:hover {
        background:$color-off-white;
        transition: 0.3s all ease-in-out;

        .post-feature-image {
          img {
            transform: scale(1.1);
            transition: 0.3s all ease-in-out;
          }

        }

      }

      &:nth-child(2n) {
        border-right:0;

        @include media-minwidth-md() {
          margin-right:0;
        }

      }

      .post-feature-image {
        overflow: hidden;
        margin-bottom:1em;
        width:140px;

        @include media-minwidth-md() {
          margin:0 auto 0 auto;
        }

        @include media-minwidth-lg() {
          width:40%;
        }

        img {
          display: block;
          width:100%;
          transition: 0.3s all ease-in-out;
        }

      }

      .post-content {
        position: relative;
        width:100%;

        @include media-minwidth-md() {
          min-height:6em;
          padding:1em;
          text-align: center;
        }

        @include media-minwidth-lg() {
          text-align: left;
        }

        h2 {
          font-size:1.5em;
          margin:0.5em 0;
          line-height:1.3em;

        }

      }

    }

  }

}

.post-header {
  background: $color-off-white!important;
  position: relative;

  @include media-minwidth-lg() {
    background:none!important;
  }

  &.has-feature-image {
    background:none;
    margin-top:1em;

    @include media-minwidth-md() {
      min-height:400px;
      display: flex;
      flex-wrap: wrap;
      margin-top:0;
    }

    &.container {
      margin:0 0 3em 0;
      padding:0;
    }

    .post-header--left {

      @include media-minwidth-md() {
        display: flex;
        justify-content: flex-end;
        width:50%;
      }

      .post-header--left-wrap {
        display: flex;
        height:100%;
        flex-direction: column;
        justify-content: space-between;
        width:100%;

        .post-header--title {
          width:100%;
        }

      }

    }

  }

  &.no-feature-image {
    display: flex;

    .post-header--left {
      margin:1em auto;
      max-width:1130px;
      padding:2em;
      width:100%;

      @include media-minwidth-lg() {
        margin:0 auto 2em auto;
      }

      .post-header--left-wrap {

        @include media-minwidth-lg() {
          display: flex;
          justify-content: space-between;
        }

        .post-header--title {
          width:100%;

          @include media-minwidth-lg() {
            width:50%;
          }

        }

      }

      .container {
        background:transparent!important;
      }

    }

  }

  .post-header--left {
    background: $color-off-white;
    margin:0;
    padding:2em;

  }

  h1 {
    margin-bottom:0;
    line-height: 1.2em;
  }

  .container {
    background:transparent!important;
  }

  .post-author {
    display:flex;
    margin:1em 0;
    position: relative;

    @include media-minwidth-lg() {
      margin:0;
    }

    &--image {
      margin-right:1em;
      width:75px;
    }

    &--content {

      p {
        font-size:1.1em;
        line-height:1.2em;
      }

    }

    a {
      height:100%;
      left:0;
      position: absolute;
      top:0;
      width:100%;
    }

  }

  .post-date {
    font-size:0.9em;
  }

  .post-content--categories {
    font-size:0.9em;
    margin-top:0.5em;
    span {
      background:$color-grey-dark;
      color:$color-white;
      font-size:0.9em;
      padding:0.25em 0.5em;
    }
  }

  &--right {

    align-items: center;
    background: $color-off-white;
    display: flex;
    justify-content: center;
    width:100%;

    @include media-minwidth-sm() {
      max-width:300px;
    }

    @include media-minwidth-md() {
      padding:1em;
      max-width:100%;
      width:50%;
    }

    @include media-minwidth-xl() {
      padding:0;
    }

    img {
      width:100%;
    }

  }
}

.post-content--meta {
  align-items: center;
  display: flex;

  @include media-minwidth-lg() {
    justify-content: start;
  }

  p {
    margin:0 0.75em 0 0;
  }

}

.post-content--categories {
  font-size:0.825em;
  span {
    background:$color-grey-light;
    display: inline-block;
    padding:0.2em 0.25em;
  }

}

.post-listing-continue {
  font-family: $font-fancy;
  font-size:1.3em;
}

a.post-listing-link {
  bottom:0;
  height:100%;
  left:0;
  position: absolute;
  width:100%;

}

.post-pagination {
  align-items: center;
  display: flex;
  margin-bottom:2em;
  width:100%;

  .page-numbers {
    background:$color-grey-light;
    color: $color-body;
    height:38px;
    padding:0.5em 0.6em;
    margin-right:8px;
    text-decoration: none;
    width:30px;

    &:hover,
    &.current {
      background:$color-body;
      color: $color-white;
    }

  }

  .prev.page-numbers,
  .next.page-numbers {
    display: none;
  }

}