body {
  font-family: $font-family;
  font-size: 17px;
}

h1, h2 {
  font-family: $font-fancy;
  font-weight: $font-weight-bold;
  margin:0 0 0.25em 0;
}

h1 {
  color: $color-h1;
  font-size: 2em;

  @include media-minwidth-md() {
    font-size: 3.5em;
  }

}

h2 {
  color: $color-h2;
  font-size: 2em;
  font-weight:$font-weight-bold;

  @include media-minwidth-xl() {
    font-size: 3em;
  }

}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.3em;
}

h5 {
  font-size: 1em;
}