.content-block {

  .content-block--container {
    position: relative;
    z-index:888;

    .content-block--container-columns {
      margin: 1.5em 0;

      ul {
        margin-bottom:2em;
      }

      @include media-minwidth-lg() {
        margin:0;
      }

    }

    .content-block--container--80 {
      margin:auto;

      @include media-minwidth-lg() {
        margin:auto;
        max-width:80%;
      }

    }

    .content-block--container--70 {
       @include media-minwidth-lg() {
        margin:auto;
        max-width:70%;
      }

    }

    .content-block--container--70-30 {
      display: grid;
      justify-content: space-between;
      
      @include media-minwidth-lg() {
        grid-template-columns: 60% 35%;
        grid-gap: 50px;
      }

      @include target-ieonly() {
        display: flex;
        flex-direction: column;

        @include media-minwidth-lg() {
          flex-direction: row;
        }

        .content-block-col-1 {
          @include media-minwidth-lg() {
            padding-right:3em;
            width:70%;
          }
        }

        .content-block-col-2 {
          @include media-minwidth-lg() {
            width:30%;
          }
        }

      }
      
    }

    .content-block--container--30-70 {
      display: grid;
      justify-content: space-between;

      @include media-minwidth-lg() {
        grid-template-columns: 35% 60%;
        grid-gap: 50px;
      }

      @include target-ieonly() {
        display: flex;
        flex-direction: column;

        @include media-minwidth-lg() {
          flex-direction: row;
        }

        .content-block-col-1 {
          @include media-minwidth-lg() {
            width:30%;
          }
        }

        .content-block-col-2 {
          @include media-minwidth-lg() {
            padding-left:3em;
            width:70%;
          }
        }

      }

    }

    .content-block--container--50-50 {
      display: grid;
      grid-template-columns: 1fr;
      
      @include media-minwidth-lg() {
        grid-template-columns: 50% 50%;
        grid-gap: 50px;
      }

      @include target-ieonly() {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-minwidth-lg() {
          flex-direction: row;
        }

        .content-block-col-1 {
          @include media-minwidth-lg() {
            width:45%;
          }
        }

        .content-block-col-2 {
          @include media-minwidth-lg() {
            width:45%;
          }
        }

      }
      
    }

    .content-block-withbg {
      padding:3em;
    }

    &-columns {
      .block_bg_blue,
      .block_bg_red {
        color:$color-white;

        a, h1, h2, h3 {
          color:$color-white;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    .content-block-col-1 {
      margin-bottom:1em;

      @include media-minwidth-lg() {
        margin-bottom:0;
      }

    }

  }

  &.content-blocks {

    &.block_bg_blue,
    &.block_bg_red {

      .content-block--container-columns {
        color:$color-white;

        a, h1, h2, h3 {
          color:$color-white;
        }

        a {
          text-decoration: underline;
        }
      }

    }
  }


}