.grid-columns {
  display: grid;
  grid-gap: 50px;
  justify-content: center;
  margin-top:3em;

  @include target-ieonly() {
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }


  &--three {

    grid-template-columns: repeat(1, 100%);

    @include media-minwidth-sm() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-minwidth-lg() {
      grid-template-columns: repeat(3, 1fr);
    }

    @include target-ieonly() {

      .grid-columns--item {
        margin-right:4em;
        width:29%;

        &:nth-child(3n) {
          margin-right:0;
        }
      }

    }

    .room-carousel-arrows {
      top:42%;
    }

  }

  &--gallery {

    grid-template-columns: repeat(1, 350px);

    @include media-minwidth-sm() {
      grid-template-columns: repeat(2, 45%);
    }

    @include media-minwidth-lg() {
      grid-template-columns: repeat(3, 30%);
    }

    .grid-columns--item:hover img {
     transform: none;
    }

    .room-carousel-arrows {
      top:42%;
    }

    .swiper-slide {
      width:100%!important;
    }


  }

  &--four {

    @include media-minwidth-sm() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-minwidth-md() {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-minwidth-lg() {
      grid-template-columns: repeat(4, 1fr);
    }

    @include target-ieonly() {

      .grid-columns--item {
        margin-right:4em;
        width:20%;

        &:nth-child(4n) {
          margin-right:0;
        }
      }



    }

  }

  &--item {
    opacity: 0.1;
    position: relative;
    transform: scale(0.9);
    z-index:8888;

    &.article--show {
      opacity: 1;
      transform: scale(1);
      transition: 0.5s all ease-in-out;
    }

    .grid-columns--item-image {
      overflow: hidden;

      img {
        display: block;
        height:100%;
        margin:auto;
        transition: 0.3s all ease-in-out;
        width:100%;
      }
    }

    .grid-columns--item-content {
      padding:1.75em 0;

      h3 {
        font-family: $font-fancy;
        font-size:2em;
        font-weight: 600;
        margin:0 0 0.5em 0;
        line-height:1em;
      }

      p {
        margin:0;
      }

      .grid-columns--item-content-title {
        font-family: $font-fancy;
        font-size:1.4em;
        margin:1em 0 0 0;
      }
    }

    a {
      @include absolute_link();
    }

  }

  .grid-w-link {

    &:hover {
      img {
        transform: scale(1.1);
        transition: 0.3s all ease-in-out;
      }
    }


  }

  .grid_text_align_center {
    text-align: center;
  }

  .grid_text_align_right {
    text-align: right;
  }

}