.rooms {

  .rooms-container {
    .room-item {
      margin:1em 0 0 0;
      padding-bottom:1em;
      position: relative;
      text-align: center;
      width:100%;

      @include media-minwidth-xl() {
        height: 510px;
      }

      @include media-minwidth-xl() {
        height: 545px;
      }

      &:after {
        content:"";
        background-image:url('assets/images/theme/black-line.svg')!important;
        background-repeat:no-repeat!important;
        background-position: 0 bottom!important;
        background-size:cover;
        position: absolute;
        bottom: -3px;
        height: 10px;
        opacity:0;
        width: 100%;
        left: 0;
        transition:all .5s ease;
      }

      &:nth-child(2n) {
        &:after {
          -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
        }
      }

      &:nth-child(3n) {
         &:after {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
          }
      }

      &:nth-child(4n) {
        &:after {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      &:hover {
        background:$color-off-white;
        transition:all .5s ease;
        &:after {
          opacity: 1;
        }

        .room-items--image img {
          transform: scale(1.1);
        }

      }

      .room-items--image {
        overflow: hidden;
        img {
          display: block;
          transition:all .5s ease;
          width:100%;
        }

      }

      .room-items--content {
        padding:1em;

        @include media-minwidth-lg() {
          padding:2em 1em;
        }

        h3 {
          font-family: $font-fancy;
          font-size:2em;
          font-weight: 600;
          margin-bottom:0.25em;

          @include media-minwidth-md() {
            min-height:1.5em;
          }

          @include media-minwidth-xl() {
            font-size:2.5em;
          }
        }

        p {
          margin:0;
        }

      }

      .room-items--content-link-text {
        font-family: $font-fancy;
        font-size:1.8em;
        font-weight: bold;
        margin-bottom:0!important;
        bottom: 12px;
        left: 0;
        right: 0;

        @include media-minwidth-xl {
          position: absolute;
        }

      }

      a {
        @include absolute_link();
      }

    }

  }

}

.colour_bg_off_white {

  &.rooms .rooms-container .room-item:hover {
    background:$color-white;
  }

}

.colour_bg_black {

  &.rooms .rooms-container .room-item:hover {
    color:$color-black;
    h3 {
      color:$color-black!important;
    }


  }

}