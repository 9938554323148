.single {

  .container.single-breadcrumb-container {
    padding-bottom:0;
  }

  .content_image {
    padding:5% 0;

    @include media-minwidth-lg() {
      padding:0.5% 0;
    }

  }

  .sc {
    &--columns {
      display: grid;
      grid-gap: 2em;
      grid-template-columns: 100%;
      padding:2em;

      @include target-ieonly() {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .content-area {
          width:100%;
          @include media-minwidth-lg() {
            width:70%;
          }

        }

      }

      @include media-minwidth-md() {
        padding:2em 4em;
      }

      @include media-minwidth-lg() {
        grid-template-columns:65% 30%;
        grid-gap: 2em;
        padding:3em 2em;
      }

      #primary {
        grid-column: 1 / 2;
      }

    }
  }

  .post-related-content {
    margin-top:2em;

    h2 {
      margin-bottom:1em;
    }
    
    &--item {
      display: flex;
      flex-direction: column;
      margin-top:1.5em;
      position: relative;

      @include media-minwidth-sm() {
        flex-direction: row;
      }

      &:hover {

        @include media-minwidth-sm() {
          background:$color-grey-lightest;
          transition: 0.3s all ease-in-out;
        }

      }

      &-image {
        width:155px;
        img {
          display: block;
          width:100%;
        }
      }

      &-content {
        padding:1em 0;
        width:100%;

        @include media-minwidth-sm() {
          padding:0.75em 1em 0.5em 1em;
        }

        .post-content--meta {

          justify-content: flex-start;

          .post-content--categories {
            display: none;

            @include media-minwidth-md() {
              display: flex;
            }

          }

        }


        h4 {
          margin-top:0.5em;
          line-height:1.3em;
        }

      }

      a {
        height:100%;
        left:0;
        position: absolute;
        top:0;
        width:100%;
      }

    }
    
  }

  .post-nav {
    align-items: start;
    display: flex;

    @include media-minwidth-lg() {
      justify-content: flex-end;
    }

    .post-prev {
      margin:-0.2em 0.75em 0 0;
      transform: rotate(180deg);
    }

  }

  &.single-events {
    img.content-image--right--frame {
      display: none;
    }
  }

  
}

.post-type-archive-events {

  .post-header--left-wrap {

    p {
      margin:0 0 0.5em 0;
    }

  }

}