.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

.entry-content {
  ul {
    li {
      margin:1em 0;
    }
  }
}

.content-block {
  ul {
    padding:0 0 0 0.5em;
    li {
      list-style: none;
      margin:0 0 0.5em 0;
      padding:0 0 0 1em;
      position: relative;
      &:before {
        color:$color-grey;
        font-family: "Font Awesome 5 Pro";
        font-size:8px;
        font-weight: 900;
        content: "\f219";
        left:0;
        position: absolute;
        top:1px;
      }
    }
  }
}

.colour-columns {
  .wp-block-column {
    &:nth-child(2) {
      background:#f7f7f7;
      padding:2em;
    }
  }
}

.wp-caption-text {
  font-size:0.825em;
  padding:0.5em;
}

blockquote {
  background:#e0e0e0;
  padding:1.5em;
}

.lead-paragraph {
  font-size:25px!important;
}

.display_archive {
  font-size:17px!important;
}
