.block-title {
  margin:0 0 0.5em 0;
  position: relative;
  z-index:999;

    @include media-minwidth-xl() {
    padding-left:0;
  }

}

.block-content {
  margin:2em 0;

  @include media-minwidth-xl() {
    width:65%;
  }

  &-wide {

    margin:0 auto;

    @include media-minwidth-md {
      margin:2em auto;
    }

    @include media-minwidth-lg() {
      width:90%;
    }

  }



}

#content-block-1 {
    padding-bottom:0;
    padding-top:0;
}

.colour_bg_white {
  background:$color-white;
  color:$color-body;
}

.colour_bg_off_white {
  background:$color-off-white;
  color:$color-body;
}

.colour_bg_black {
  background:$color-black;
  color:$color-white;

  .block-title,
  .block-content,
  a,
  h1, h2, h3, h4 {
    color:$color-white!important;
  }

  a {
    color:$color-white!important;
    text-decoration: underline;
  }

}

/* Block settings */
.content-block.padding_remove_bottom {
  padding-bottom:0;
}
.content-block.padding_remove_top {
  padding-top:0;
}
.content-block.padding_remove_top_and_bottom {
  padding-bottom:0;
  padding-top:0;
}


.content-block {
  padding:5% 0;
  position: relative;

  @include media-minwidth-lg() {
    padding:3% 0;
  }

}