.form-block {

  .container {
    background:none!important;
    max-width:800px;
    padding:0 2em;

    ul li {
      padding-left:0!important;
    }

    .gform_description {
      line-height: 1.6em;
      margin-bottom: 2em;
    }

  }



}