.w-100 {
  width: $size-full;
}

.h-100 {
  height: $size-full;
}

.m-auto {
  margin: $size-auto;
}


@include media-minwidth-md {
  .w-md-1-4 {
    flex-basis: $size-1-4;
  }

  .w-md-3-4 {
    flex-basis: $size-3-4;
  }
}