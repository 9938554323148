.sh__search {
  background:#e0e0e0;
  display: flex;

  .container {
    padding:1em;

    form {
      float:right;
      margin:0;
      max-width:400px;
    }
  }
}

.search-form {

  .screen-reader-text {
    opacity: 0;
  }

  &.small-search-from {
    border: 1px solid $color-grey;
    input[type="search"] {
      border: 0;
      width: 90%;
    }
  }
  display: flex;
  float: left;
  margin: 1em 0;
  width: $size-full;
  input {
    padding: 0.75em;
    &[type="search"] {
      border: 1px solid $color-grey;
    }
    &[type="submit"] {
      background: $color-brand;
      color: #fff;
      cursor: pointer;
    }
  }
  button {
    color: $color-brand;
    width: 10%;
    transition: 0.3s all ease-in-out;
    &:hover {
      background: $color-brand;
      color: #fff;
      transition: 0.3s all ease-in-out;
    }
  }
}

.search-results {
  article {
    border-bottom: 1px solid #e0e0e0;
    clear: both;
    display: flex;
    flex-direction: column;
    padding-bottom:1em;
    position: relative;
    width:100%;

    @include media-minwidth-md() {
      flex-direction: row;
    }

    header {
      padding:1em 1em 0 0;

    }

      h2 {
        font-size: 1.2em;
        margin: 0;

        @include media-minwidth-lg() {
          font-size: 1.5em;
        }


        a {
          color:$color-body;
          text-decoration: none;
          span {
            color:$color-brand;
            font-size:0.7em;
            margin-left:1em;
            opacity: 0;
            transition: $default_transition;
          }

          &:hover {
            span {
              opacity: 1;
              transition: $default_transition;
            }
          }


        }

      }


    &:last-child {
      border-bottom: 0;
    }

  }

  .result-post-navigation {
    display: flex;
    margin-top:2em;
    .current,
    .dots{
      padding:0.75em 1em;
      border:1px solid #fff;
    }
    .current {
      background:$color-grey-light;
    }
    a {
      background:$color-body;
      border:1px solid #fff;
      color:#fff;
      padding:0.75em 1em;
      text-decoration: none;
      transition:0.3s all ease;
      &:hover {
        background:$color-grey-light;
        color:#000;
      }
    }
  }

}