.carousel {

  .carousel-swiper-container {
    padding-top:1em;
    z-index:8888;
  }

  .swiper-slide {
    text-align: center;

    h3 {
      font-family: $font-fancy;
      font-size:2em;
      margin:0.5em 0;
    }

  }

}