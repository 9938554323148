.gallery {
  overflow: hidden;

  &--with-sidebar {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    @include media-minwidth-lg() {
      justify-content: space-between;
      flex-direction: row;
    }

  }

  .gallery-filter {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include target-ieonly() {
      width:100%;

      @include media-minwidth-lg() {
          width:30%;
      }


    }

    button {
      color:$color-body;
      display: block;
      margin:0.75em 0 0 0;

      &:focus {
        outline: 0;
      }

      &:hover,
      &.gallery-category-active {
        font-weight: bold;
      }

    }

    .gallery-content {
      font-size:$text-base;
    }

  }

  .gallery-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;

    @include media-minwidth-lg() {
      width:25%;
    }

  }

  .gallery-images {
    opacity: 0.3;
    position: relative;
    transform: scale(0.9);
    width:100%;
    z-index:999;

    @include media-minwidth-lg() {
      width:70%;
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      transition: 0.3s all ease-in-out;
    }

    .gallery-loading {
      align-items: center;
      background:rgba(255,255,255, 0.9);
      display: none;
      justify-content: center;
      height:100%;
      left:0;
      margin:auto;
      position: absolute;
      right:0;
      top:0;
      width:100%;
      z-index:888;
      transition: 0.3s all ease-in-out;

      .loading-spinner {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 3px solid rgba(0,0,0,.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }

      @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
      @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }

    }

  }

  #response-call {
    display: none;

    &.show {
      display: block;
    }

  }

  .swiper-wrapper {
    padding-top:3em;

    .swiper-slide {

      opacity: 0!important;

      img {
        width:100%;
      }

      &.swiper-slide-prev {
        opacity: 0!important;
      }

      &.swiper-slide-active {
        opacity: 1!important;
      }
    }

  }

  .swiper-pagination {
    right:0;
    top:0;

    span {
      margin-left:0.5em;
    }

  }

  /* .gallery-navigation {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;
    top: -15px;
    z-index: 8888;

    @include target-ieonly() {
      top: -55px;
    }

    .gallery-prev {
      margin-right: 0.75em;

    }

  } */

}


.colour_bg_black {
  i,
  .gallery-filter button {
    color: $color-white;
  }
}
