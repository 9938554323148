.collapsible-block.tabs-vertical {

  .collapsible-block--container {
    margin:auto;

    .collapsible-block-toggle {
      margin:2em 0 0 0;

      @include media-minwidth-lg() {
        text-align: right;
      }

      button {
        background:$color-brand;
        color:$color-black;
        padding:0.5em 1em;
        transition: $default_transition;

        &.collapsible-button-active {
          background:$color-body;
          color:$color-white;
          transition: $default_transition;
        }

      }
    }

    .collapsible-block--items {
      margin:1.5em 0 2em 0;
    }

    .collapsible-block--items-items {
      background:$color-grey-light;
      margin-bottom:0.5em;
      padding:1em;
      transition: $default_transition;

      &:hover,
      &.collapsible-open {
        /* background:$color-grey-light; */
        transition: $default_transition;

        button {
          color:$color-black;
          transition: $default_transition;

          &:focus,
          &:hover {
            outline: 0;
          }

        }
      }

      &:hover {
       /* background:$color-grey!important; */

        button {
          font-weight:bold;
        }

      }

      &.collapsible-open {

        span {
          &:before {
            opacity: 0;
            transition: $default_transition;
          }
        }

        button {
          font-weight:bold;
        }


      }

      button {
        cursor: pointer;
        display: inline-block;
        margin:auto;
        position: relative;
        text-align: left;
        transition: $default_transition;
        width:100%;

        span {
          position: absolute;
          right: 0;
          top: 0;

          &:before,
          &:after {
            background: $color-body;
            content: "";
            position: absolute;
          }

          &:before {
            height: 15px;
            right: 10px;
            top: 4px;
            width: 2px;
            transition: $default_transition;
          }

          &:after {
            height: 2px;
            right: 3px;
            top: 11px;
            width: 16px;

          }

        }

      }

      .collapsible-block--items-items-content {
        display: none;
        padding:2em 0 0 0;
      }

    }

  }

  &.colour_bg_off_white {

    .collapsible-block--items-items {
      background:$color-white;
      border-color:$color-white;

      &.collapsible-open {
        background:$color-white;
        border-color:$color-brand;
      }



      button {
        span {
          &:before,
          &:after {
            background:$color-black;
          }
        }

        &:hover {
          span {
            &:before,
            &:after {
              background:$color-black;
            }
          }
        }
      }

      h3 {
        color:$color-white;
      }

      .collapsible-block--items-items-content {
        color:$color-body;

        a {
          color:$color-link;
        }

      }

    }
  }

}

.collapsible-block.tabs-horizontal {

  .tabs-horizontal--buttons {
    display: flex;
    flex-direction: column;
    margin:2em 0;

    @include media-minwidth-md() {
      flex-direction: row;
    }

    button {
      background: #ffffff;
      color: #191919;
      display: inline-block;
      font-size: 1.1em;
      margin:0 1em 0 0;
      outline: none;
      padding: 0.5em 1em;
      position: relative;
      text-decoration: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;

      @include media-minwidth-md() {
        align-self: center;
      }

      &.tab-horizontal-button--active {
        background:$color-brand;
      }

    }


  }

  .collapsible-block--items {
    display: flex;
  }

  .collapsible-block--items-items {
    display: none;
    padding-top:2em;
    width:100%;

    &.collapsible-item-1 {
      display: block;
    }

    .collapsible-block--items-items-content {
      display:flex;
      justify-content: space-between;
      flex-direction: column;

      @include media-minwidth-md() {
       flex-direction: row;
      }

    }

    .collapsible-item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width:100%;

      @include media-minwidth-md() {
        width:40%;
      }

      h3 {
        font-family: $font-fancy;
        font-size:2em;
      }

    }

  }

  .tab-gallery {
    margin-top:2em;
    position: relative;
    width:100%;

    @include media-minwidth-md() {
      margin-top:0;
      width:50%;
    }

    .gallery-swiper-container {
      overflow: visible;
      width:91%;

      .swiper-slide {
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 79%;

        p {
          display: none;
          font-size: 0.8em;
          position: absolute;
          z-index: 9999;
          bottom: -17px;
          right: 0;
          color: #fff;
          text-align: right;
          width: 100%;

        }

        &.swiper-slide-active {
          p {
            background: rgba(0,0,0,0.5);
            display: block;
            padding: 0.5em 1.5em 0.75em 0;
          }
        }

        img {
          height:100%;
        }



      }

      .gallery-navigation {
        display: flex;
        position: absolute;
        right: 0;
        top: -11px;
        z-index: 9999;

        .gallery-nav-arrow {
          margin-left:1em;
        }


      }

      .swiper-pagination {
        bottom:-52px;
      }

    }

    .tab-video {
      background:$color-brand;
      height:100%;
      position: absolute;
      width:100%;

      iframe {
        height: 100%;
        width:100%;
        position: relative;
        z-index:999;
      }

    }

    .gallery-frame {
      left:2px;
      position: absolute;
      right:0;
      top:-12px;
      z-index:888;

      @include media-minwidth-lg() {
        top:-14px;
      }

    }

  }



}