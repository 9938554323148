.gallery-listing {

  .room-carousel-arrows {
    opacity: 1;
    position: absolute;
    top:21%;
    width:100%;
    z-index:888;
    transition: $default_transition;

    @include media-minwidth-lg() {
      opacity: 0;
    }

    .room-prev {
      left:0;
    }

    .room-next {
      right:0;
    }

  }

  .room-nav-arrow {
    background:rgba(0,0,0,0.7);
    cursor: pointer;
    padding:0.5em;
    position: absolute;
    z-index:9999;
    transition: 0.3s all ease-in-out;

    &.swiper-button-disabled {
      opacity: 0.3;
    }

    i {
      color:$color-white;
      font-size:2em;
    }

  }

  &:hover {
    transition: 0.3s all ease-in-out;

    &:after {
      opacity: 1;
    }

    .room-carousel-arrows {
      opacity: 1;
    }

  }

  }

