.map-block {

  &--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-minwidth-lg() {
      flex-direction: row;
    }


    &-left {
      div.map-block--half-content {
        display: flex;
        flex-direction: column;
        padding:3em;

        @include media-minwidth-lg() {
          padding:7em 2em 0 2em;
        }

        @include media-minwidth-xl() {
          padding:4em 5em 0 16%;
        }

        .map-block-columns {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-top:1em;

          @include media-minwidth-sm() {
            flex-direction: row;
          }

          @include media-minwidth-lg() {
            flex-direction: column;
          }

          @include media-minwidth-xl() {
            flex-direction: row;
          }

          .map-content-column {
            width:100%;

            @include media-minwidth-sm() {
              width:45%;
            }

            @include media-minwidth-lg() {
              width:100%;
            }

            @include media-minwidth-xl() {
              width:45%;
            }

          }

        }

      }
    }

    &-right {
      iframe {
        position: relative;
        width:100%;
        z-index:5555;
      }
    }

  }




  &--half {
    width:100%;

    @include media-minwidth-lg() {
      width:50%;
    }


  }

}
